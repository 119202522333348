import { useState } from 'react';
import fbIcon from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import insta from '../images/instagram.svg';
import youtube from '../images/youtube.svg';
import logo from '../images/logoDark.svg';

export const Footer = () => {

    return(
    <footer>
        <div className="container">
          <div className="innerFooter">
            <div className="logoMain">
             
            </div>
          </div>
          <div className="row footer2Main">
          <div className="col-xl-3 col-lg-3 ">
              <a href="#" >
                <img src={logo} alt="" />
              </a>
              <p className="desc mb-4 mt-3">Let’s create something Unique  and inspiring.</p>
            </div>
            <div className="col-xl-3 col-md-3 col-sm-4 col-6">
              <h4 className="quickLinks">Get in Touch</h4>
              <ul>
                <li><a href="#"> A : Harju maakond, Tallinn, Kesklinna linnaosa, Tornimäe tn 5, 10145 </a></li>
                <li><a href="#"> E : organization@solstech.io </a></li>
                <li><a href="#"> P : +4915775568031 </a></li>
              </ul>
            </div>
            <div className="col-xl-3 col-md-3 col-sm-4 col-6">
              <h4 className="quickLinks">Our Product</h4>
              <ul>     
                <li><a href="#"> Home</a></li>
                <li><a href="#"> About </a></li>
                <li><a href="#"> Portfolio </a></li>
                <li><a href="#"> Services </a></li>
                <li><a href="#"> Contact </a></li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 ">
              <h4 className="quickLinks mb-4">Connect with us</h4>
              <div className="subsMain">
                <input type="text" />
                <button placeholder='Enter your email' type="submit">Subscribe</button>
              </div>
            </div>
          </div>
          <div className="copyRight">
            <div>
            SolsTech, 2024 All Rights Reserved
            </div>
              <div className="socialMain">
                <a href="#" target="_blank">
                  <img src={fbIcon} alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src={twitter} alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src={insta} alt="" />
                </a>
                <a href="#" target="_blank">
                  <img src={youtube} alt="" />
                </a>
              </div>
          </div>
        </div>
    </footer>
    )
}